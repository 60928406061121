import React from "react";
import press1 from "../pics/press1.jpg";
import press2 from "../pics/press2.jpg";

export default function PressShots() {
    return (
        <div>
            <img className="press-shot" src={press1} alt="" />
            <p className="press-shot-caption">photo by Tom Stephens</p>
            <img className="press-shot" src={press2} alt="" />
            <p className="press-shot-caption">photo by Tom Stephens</p>
        </div>
    )
};