import React from "react";
import IDATD from "../pics/IDATD.jpg";
import BA from "../pics/BA.jpg";
import ss from "../pics/ss.jpeg";
import canola from "../pics/canola.jpeg";

export default function Music() {
    const LineBreak = () => (
        <br/>
    );
    
    return (
        <div>
            <div className="release">
                <div className="release-one">
                    <img className="release-art" src={canola} alt="" />
                </div>
                <div className="release-two">
                    <h4 className="release-title">Swimmers</h4>
                    <h5 className="release-date">2025</h5>
                    <p className="release-notes">Recorded in Electric Avenue and Nate's room</p>
                    <p className="release-notes">Mixed by Nate</p>
                    <p className="release-notes">Mastered by Snowy Halliwell</p>
                    <p className="release-notes">Artwork by Josh Ibrahim</p>
                    <LineBreak />
                    <p className="release-notes">Vocals, Guitar, Synth / Nathan Stephenson</p>
                    <p className="release-notes">Vocals / Esther Ibrahim</p>
                    <p className="release-notes">Guitar / Cameron Crew</p>
                    <p className="release-notes">Bass / Rei Wasserman</p>
                    <p className="release-notes">Drums / Blair Mottau</p>
                    <LineBreak />
                    <p className="release-notes">These songs were recorded on Gadigal and Wangal land.</p>
                    <div className="release-buttons">
                        <a href="https://casualfan.bandcamp.com/track/mercy-me" className="release-button" target="_blank" rel="noopener noreferrer">BUY</a>
                        <a href="https://open.spotify.com/track/4pIrCpSwJnLSlPhO8x3RLa?si=5e93568b1195434b" className="release-button" target="_blank" rel="noopener noreferrer">STREAM</a>
                    </div>
                </div>
            </div>
            <div className="release">
                <div className="release-one">
                    <img className="release-art" src={canola} alt="" />
                </div>
                <div className="release-two">
                    <h4 className="release-title">Mercy Me</h4>
                    <h5 className="release-date">2024</h5>
                    <p className="release-notes">Recorded in Electric Avenue and Nate's room</p>
                    <p className="release-notes">Mixed by Nate</p>
                    <p className="release-notes">Mastered by Guy Pidgeotto</p>
                    <p className="release-notes">Artwork by Josh Ibrahim</p>
                    <LineBreak />
                    <p className="release-notes">Vocals, Guitar, Synth / Nathan Stephenson</p>
                    <p className="release-notes">Vocals / Esther Ibrahim</p>
                    <p className="release-notes">Guitar / Cameron Crew</p>
                    <p className="release-notes">Bass / Rei Wasserman</p>
                    <p className="release-notes">Drums / Blair Mottau</p>
                    <LineBreak />
                    <p className="release-notes">These songs were recorded on Gadigal and Wangal land.</p>
                    <div className="release-buttons">
                        <a href="https://casualfan.bandcamp.com/track/mercy-me" className="release-button" target="_blank" rel="noopener noreferrer">BUY</a>
                        <a href="https://open.spotify.com/track/4pIrCpSwJnLSlPhO8x3RLa?si=5e93568b1195434b" className="release-button" target="_blank" rel="noopener noreferrer">STREAM</a>
                    </div>
                </div>
            </div>
            <div className="release">
                <div className="release-one">
                    <img className="release-art" src={ss} alt="" />
                </div>
                <div className="release-two">
                    <h4 className="release-title">sun / sideways</h4>
                    <h5 className="release-date">2024</h5>
                    <p className="release-notes">Recorded in Nate's room</p>
                    <p className="release-notes">Mixed by Nate</p>
                    <p className="release-notes">Mastered by Snowy Halliwell</p>
                    <p className="release-notes">Artwork by Nate</p>
                    <LineBreak />
                    <p className="release-notes">Everything / Nathan Stephenson</p>
                    <p className="release-notes">Additional Vocals / Esther Ibrahim</p>
                    <p className="release-notes">Drums (sun) / Blair Mottau</p>
                    <LineBreak />
                    <p className="release-notes">These songs were recorded on Wangal land.</p>
                    <div className="release-buttons">
                        <a href="https://casualfan.bandcamp.com/album/sun-sideways" className="release-button" target="_blank" rel="noopener noreferrer">BUY</a>
                        <a href="https://open.spotify.com/album/5xLAEvhadBsWOROTBHYaJT?si=-sTbK09RQMCgO3hQployDg" className="release-button" target="_blank" rel="noopener noreferrer">STREAM</a>
                    </div>
                </div>
            </div>
            <div className="release">
                <div className="release-one">
                    <img className="release-art" src={BA} alt="" />
                </div>
                <div className="release-two">
                    <h4 className="release-title">Bono's America</h4>
                    <h5 className="release-date">2023</h5>
                    <p className="release-notes">Recorded at Liam Judson's Sonic Funhouse</p>
                    <p className="release-notes">Mixed by Liam Judson</p>
                    <p className="release-notes">Mastered by Snowy Halliwell</p>
                    <p className="release-notes">Artwork by Blair Mottau</p>
                    <LineBreak />
                    <p className="release-notes">Vocals, Guitar / Nathan Stephenson</p>
                    <p className="release-notes">Vocals, Guitar / Stevie Lujan</p>
                    <p className="release-notes">Vocals, Synth / Esther Moran</p>
                    <p className="release-notes">Guitar / Cameron Crew</p>
                    <p className="release-notes">Bass / Rei Wasserman</p>
                    <p className="release-notes">Drums / Blair Mottau</p>
                    <LineBreak />
                    <p className="release-notes">These songs were recorded on Gadigal, Dharug and Wangal land.</p>
                    <div className="release-buttons">
                        <a href="https://casualfan.bandcamp.com/album/bonos-america" className="release-button" target="_blank" rel="noopener noreferrer">BUY</a>
                        <a href="https://open.spotify.com/album/6shE1AZw5UsWw5iAKvN8zq" className="release-button" target="_blank" rel="noopener noreferrer">STREAM</a>
                    </div>
                </div>
            </div>
            <div className="release">
                <div className="release-one">
                    <img className="release-art" src={IDATD} alt="" />
                </div>
                <div className="release-two">
                    <h4 className="release-title">I've Dreamt About These Days</h4>
                    <h5 className="release-date">2020</h5>
                    <p className="release-notes">Recorded with Mark Myers at Big Sister Studio, Cairns</p>
                    <p className="release-notes">Tracks 1, 4, 5, 7 & 8 Mixed by Nathan Stephenson</p>
                    <p className="release-notes">Tracks 2, 3, 6 Mixed by Tim Fitz</p>
                    <p className="release-notes">Mastered by William Bowden</p>
                    <p className="release-notes">Artwork by Hanke Arkenbout</p>
                    <LineBreak />
                    <p className="release-notes">Vocals, Guitars, Bass, Synths etc. / Nathan Stephenson</p>
                    <p className="release-notes">Drums / Blair Mottau</p>
                    <p className="release-notes">Guitars, Synth, Percussion, Vocal & Trumpet / Mark Myers</p>
                    <p className="release-notes">Vocals / Esther Moran, Stevie Lujan & Bri Burns</p>
                    <p className="release-notes">Pedal Steel on Track 3 / Tim Fitz</p>
                    <LineBreak />
                    <p className="release-notes">These songs were recorded on Yidinji land.</p>
                    <div className="release-buttons">
                        <a href="https://casualfan.bandcamp.com/album/ive-dreamt-about-these-days" className="release-button" target="_blank" rel="noopener noreferrer">BUY</a>
                        <a href="https://open.spotify.com/album/3Gqd69hIUQc1uVWWABLcnT" className="release-button" target="_blank" rel="noopener noreferrer">STREAM</a>
                    </div>
                </div>
            </div>
        </div>
    )
};