import React from "react";
import canola from "../pics/canola.jpeg";

export default function Home() {
    return (
        <div className="announce">
            <h2 className="announce-title">New Single "Swimmers" Out Now</h2>
            <img className="announce-art" src={canola} alt="" />
            <div className="announce-buttons">
                    <a href="https://casualfan.bandcamp.com/track/swimmers" className="announce-button" target="_blank" rel="noopener noreferrer">BUY</a>
                    <a href="https://open.spotify.com/album/5z0VYWhf2a73XGug8maapA?si=O13xANn9SRaTTXQZ03hv_Q" className="announce-button" target="_blank" rel="noopener noreferrer">STREAM</a>
            </div>        
        </div>
    )
};

{/* <div className="announce-body-one">
                    <img className="announce-art" src={canola} alt="" />
                </div>
                <div className="announce-body-two">
                    <p className="announce-notes">
                        𝙞𝙣 𝙩𝙝𝙚 𝙬𝙖𝙩𝙚𝙧 𝙞𝙩 𝙛𝙚𝙚𝙡𝙨 𝙡𝙞𝙠𝙚 𝙖𝙪𝙩𝙪𝙢𝙣
                    </p>
                    <p className="announce-notes">
                        It’s about swimming in autumn, written in the depths of covid lockdowns ~ cooped up and dreaming of sunnier times 🌞
                    </p>
                    <p className="announce-notes">
                        Recorded at electric ave and at home on unceded Gadigal and Wangal lands, during the summer of 2024 just in time for the autumn of 2025
                    </p>
                    <p className="announce-notes">
                        Recorded and mixed by Rei & Nate
                        Mastered by Snowy Halliwell
                        Artwork by Josh Ibrahim
                    </p>
                    <div className="announce-buttons">
                        <a href="https://casualfan.bandcamp.com/track/swimmers" className="announce-button" target="_blank" rel="noopener noreferrer">BUY</a>
                        <a href="https://open.spotify.com/album/5z0VYWhf2a73XGug8maapA?si=O13xANn9SRaTTXQZ03hv_Q" className="announce-button" target="_blank" rel="noopener noreferrer">STREAM</a>
                    </div>
                </div> */}